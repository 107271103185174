<template>
  <div class="container">
    <topHeader title="补助金"></topHeader>
    <div style="height: 45px;">
      <van-tabs v-model="active" @change="handleChangeTab">
        <van-tab name="first" title="补助账号">
        </van-tab>
        <van-tab name="second" title="补助记录">
        </van-tab>
      </van-tabs>
    </div>
    <div v-if="active === 'first'" class="allowance-content">
      <div class="allowance-top">
        <div class="allowance-top-title">我的补助金</div>
        <CommonBorder></CommonBorder>
        <div class="allowance-top-content">
          <img style="height: 24px;width: 24px;margin-right: 10px;" src="../../assets/images/allowance.png" alt="">
          <div>{{ allowance }}</div>
        </div>
      </div>
      <div style=" border-radius: 10px;flex: 1;overflow: auto;box-sizing: border-box;display: flex;flex-direction: column;">
        <div style="color: #333;font-size: 16px;margin-bottom: 12px;height: 20px;">待领取</div>
        <van-list v-if="allowanceList && allowanceList.length > 0">
          <div v-for="(item, index) in allowanceList" :key="index" >
            <div style="background: #ffa900;
            border-radius: 10px;
            color: #fff;
            margin-bottom: 15px;height: 97px;display: flex;flex-direction: column;justify-content: space-between;" @click="handleReceive(item)">
            <div style="box-sizing: border-box;
              padding: 0 16px;
              height: 40px;
              line-height: 40px;display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
              <div>补助金</div>  
              <div style="display: flex;flex-direction: row;align-items: center;">
                <img style="height: 24px;width: 24px;margin-right: 10px;" src="../../assets/images/allowance.png" alt="">
                  <div>{{ getMoney(item.amount / 100) }}</div>
                </div>  
              </div>
            <div style="box-sizing: border-box;
              padding: 0 16px;
              height: 31px;
              line-height: 31px;display: flex;flex-direction: row;justify-content: space-between;align-items: center;background: #B27600;border-radius: 0 0 10px 10px;font-size: 16px;">
              领取激活
            </div>
          </div>
          </div>
        </van-list>
        <div style="flex:1; display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;" v-else>
          <img style="width: 280px;
          height: 150px;
          margin: 0px auto 20px;" src="@/assets/images/noneImg.png" alt="" />
          <div style="text-align: center;
          color: #666666;
          font-size: 16px;">暂无内容</div>
        </div>
      </div>
    </div>
    <div v-if="active === 'second'" class="allowance-content" style="padding: 10px 0;">
      <div class="allowance-main">
        <van-list
          v-if="list && list.length > 0"
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          class="allowance-main-list"
        >
          <div v-for="(item, index) in list" :key="index" >
            <div class="allowance-main-items">
              <div>{{ item.createTime }}</div>
              <div v-if="item.status === 1" style="color: #FFA900;">+{{ getMoney(item.amount / 100) }}</div>
              <div v-if="item.status === 2">-{{ getMoney(item.amount / 100) }}</div>
            </div>
            <CommonBorder></CommonBorder>
          </div>
        </van-list>
        <div class="allowance-main-empty" v-else>
          <img class="empty-img" src="@/assets/images/noneImg.png" alt="" />
          <div class="empty-text">暂无内容</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import { currencyTransform, getMoney } from '@/utils/util';
import { allowanceList, getAllowanceList, getAllowanceSuccessList, getReceiveAllowance } from '@/api/allowance';
import topHeader from '@/components/topHeader';
import CommonBorder from '@/components/commonBorder';
export default {
  name: 'Allowance',
  components: {
    topHeader,
    CommonBorder,
  },
  data() {
    return {
      loading: false,
      finished: false,
      cancel,
      allowance: 0,
      active: 'first',
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      list: [],
      allowanceList: []
    };
  },
  computed: {
    money() {
      return (fee) => {
        return currencyTransform(fee);
      };
    }
  },
  created() {
    this.getAllowanceListAll()
  },
  methods: {
    getMoney,

    async getAllowanceListAll() {
      const { $store } = this;
      this.allowance = (Number($store.getters.allowance) / 100).toFixed(2);
      const res = await getAllowanceList()
      if (res.code === 0) {
        this.allowanceList = res.data
      }
    },

    handleChangeTab(val) {
      if (val === 'second') {
        this.pagination = {
          pageNo: 1,
          pageSize: 10,
          total: 0
        },
        this.list = []
        this.$nextTick(() => {
          this.finished = false
          this.getAllowanceList();
        });
      }else {
        this.getAllowanceListAll();
      }
    },

    onLoad() {
      const { pageNo, pageSize, total } = this.pagination;
      if (pageNo * pageSize >= total) {
        this.finished = true;
      } else {
        this.pagination.pageNo = this.pagination.pageNo + 1;
        this.getAllowanceList();
      }
    },
    toHome() {
      this.$router.push({ name: 'Home' });
    },
    async getAllowanceList() {
      this.loading = true;
      const res = await getAllowanceSuccessList(this.pagination);
      if (res.code === 0) {
        if (this.pagination.current === 1) {
          this.list = res.data.records;
        } else {
          res.data.records.map((item) => {
            this.list.push(item);
          });
        }
        this.pagination = {
          pageNo: res.data.current,
          pageSize: res.data.size,
          total: res.data.total
        };
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    toBack() {
      this.$router.back();
    },

    async handleReceive(item) {
      const res = await getReceiveAllowance({
        id: item.id
      });
      if (res.code === 0) {
        this.$message({
          message: '领取补助成功!',
          type: "success"
        });
        await this.$store.dispatch('user/getInfo');
        this.getAllowanceListAll()
      }
    },
  }
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .allowance-content{
    box-sizing: border-box;
    padding: 10px 16px;
    background: #f7f7f7;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .allowance-top{
      background: #ffa900;
      border-radius: 10px;
      color: #fff;
      margin-bottom: 28px;
      .allowance-top-title{
        box-sizing: border-box;
        padding: 0 16px;
        height: 40px;
        line-height: 40px;
      }
      .allowance-top-content{
        box-sizing: border-box;
        padding: 16px;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .allowance-main{
      border-radius: 10px;
      flex: 1;
      overflow: auto;
      .allowance-main-list{
        .allowance-main-items{
          background: #fff;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 44px;
          box-sizing: border-box;
          padding: 8px 16px;
          color: #666666;
        }
      }
      .allowance-main-empty{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        .empty-img{
          width: 280px;
          height: 150px;
          margin: 0px auto 20px;
        }
        .empty-text{
          text-align: center;
          color: #666666;
          font-size: 16px;
        }
      }
    }
  }
}
::v-deep .van-row {
  border-bottom: 1px solid #ccc;
  padding: 0.6rem 0;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}

::v-deep .van-tab{
  font-size: 16px;
}
::v-deep .van-tabs__line{
  background: #00B893;
  width: 60px;
}
::v-deep .van-tab--active{
color: #00B893;
}
</style>
