import request from '@/utils/request'

export function allowanceList(params) {
  return request({
    url: `/icbc/allowances/pagination`,
    method: 'get',
    params
  })
}

// 待领取补助 allowances/receiveLog
export function getAllowanceList(params) {
  return request({
    url: `/icbc/allowances/receiveLog`,
    method: 'get',
    params
  })
}

// 领取补助 allowances/receive    detailId
export function getReceiveAllowance(params) {
  return request({
    url: `/icbc/allowances/receive?detailId=${params.id}`,
    method: 'get',
  })
}

// 已成功补助记录
export function getAllowanceSuccessList(params) {
  return request({
    url: `/icbc/allowances/receiveSucess`,
    method: 'get',
    params
  })
}